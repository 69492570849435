// footer
.footer-primary {
    border-top: 1px $grey solid;
    padding: 30px 0;
    .heading-box-small {
        text-transform: uppercase;
        margin-bottom: 15px;
    }
    ul {
        li {
            text-transform: uppercase;
            margin-bottom: 5px;
            color: $light-color-text;
        }
    }
    @include media-breakpoint-down(md) {
        padding-top: 0;
        .col-6 {
            margin-top: 30px;
        }
    }
}
.footer-copy {
    border-top: 1px $grey solid;
    padding: 15px 0;
    p {
        span {
            color: $primary-color;
        }
    }
    a {
        float: right;
    }
    @include media-breakpoint-down(xs) {
        text-align: center;
        a {
            float: none;
            display: inline-block;
            margin-top: 5px;
        }
    }
}