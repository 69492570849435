// homepage
.landing-section {
    border-bottom: 1px $grey solid;
    padding: 30px 0;
    .small-box {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-down(md) {
        padding: 15px 0;
        .news-full-image-box {
            margin-bottom: 15px;
        }
    }
}

// 

.base-section {
    
}
.news-sep-list-content {
    margin-top: 30px;
    .news-sep-image-box {
        padding-bottom: 45px;
        border-bottom: 1px $grey solid;
        margin-bottom: 30px;
        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-down(md) {
        margin-top: 20px;
        .news-sep-image-box {
            padding-bottom: 30px;
            margin-bottom: 20px;
        }
    }
}

//

.events-content {
    margin-top: 40px;
    .event-image-item {
        border-bottom: 1px $grey solid;
    }
    .event-text-item {
        border-bottom: 1px $grey solid;
        &:last-child {
            border-bottom: none;
        }
    }
    @include media-breakpoint-down(md) {
        margin-top: 20px;
    }
    @include media-breakpoint-down(xs) {
        .event-text-item {
            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.article-list-sidebar {
    margin-top: 45px;
    .article-image-item {
        border-bottom: 1px $grey solid;
        margin-bottom: 15px;
        padding-bottom: 12px;
        &:last-child {
            border-bottom: none;
        }
    }
}

//

.top-news-content {
    margin-top: 30px;
    &:first-of-type {
        margin-top: 45px;
    }
    .news-image-small-item {
        padding-bottom: 16px;
        border-bottom: 1px $grey solid;
    }
    @include media-breakpoint-down(md) {
        &:first-of-type {
            margin-top: 20px;
        }
    }
}





























