// contact
.contact-section {
    @include media-breakpoint-down(md) {
        .col-lg-3 {
            order: 2;
        }
        .col-lg-6 {
            order: 1;
        }
    }
    
}
.contact-content {
    margin-top: 40px;
    p {
        margin-top: 15px;
    }
    @include media-breakpoint-down(md) {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
.contact-form {
    margin: 30px auto 0 auto;
    .row {
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .btn-border {
        float: right;
        border-color: $primary-color;
        &:hover {
            background: $primary-color;
            color: $white;
        }
    }
}
.input-primary, 
.textarea-primary {
    border: 1px $primary-color solid;
}
.input-primary {
    width: 100%;
    height: 45px;
    display: block;
    padding: 0 15px;
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
}
.textarea-primary {
    width: 100%;
    height: 165px;
    display: block;
    padding: 15px 15px;
}
