// buttons
.btn-border {
    background: none;
    font-weight: 700;
    font-size: 1.4rem;
    border: 1px $grey solid;
    display: inline-block;
    padding: 10px 20px;
    @extend .transition;
    &:hover {
        border-color: $primary-color;
    }
}