// header
.header-primary {
    padding: 20px 0 16px 0;
    border-bottom: 1px $grey solid;
    .logo {
        position: relative;
        a {
            display: inline-block;
        }
    }
    .social-list {
        float: right;
        position: relative;
        top: -1px;
    }
    nav {
        float: right;
        margin-right: 20px;
        li {
            display: inline-block;
            text-transform: uppercase;
            margin-right: 15px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .social-list {
            float: none;
            position: absolute;
            top: -35px;
            right: 60px;
        }
        nav {
            float: none;
            margin: 15px 0 0 0;
            text-align: center;
            display: none;
            li {
                margin-right: 0;
                display: block;
            }
            a {
                display: block;
                padding: 12px 15px;
                &:hover {
                    background: $primary-color;
                    color: $white;
                }
            }
        }
    }
    @media(max-width: 400px) {
        padding: 50px 0 15px 0;
        .logo {
            max-width: 140px;
            margin: 0 auto;
        }
        .social-list {
            top: -74px;
            right: 0;
            left: 0;
            text-align: center;
        }
        .hamburger {
            top: 9px;
        }
    }
}

.hamburger {
	width: 35px;
	height: 29px;
	padding: 4px;
	transition: all .25s ease;
	-webkit-transition: all .25s ease;
	position: absolute;
    top: 14px;
    right: 11px;
	z-index: 2;
	display: none;
    cursor: pointer;
	.menu-bar {
		position: absolute;
		border-radius: 2px;
		width: 80%;
		transition: .5s;
		-webkit-transition: .3s;
	}
	.menu-bar-top {
        background: $primary-color;
        height: 2px;
		top: 0;
	}
	.menu-bar-middle {
		background: $primary-color;
        height: 2px;
		margin-top: 4px;
		margin-bottom: 7px;
		top: 4px;
	}
	.menu-bar-bottom {
		background: $primary-color;
        height: 2px;
		top: 16px;
	}
    .menu-text {
        position: absolute;
        top: -2px;
        left: 40px;
        white-space: nowrap;
        font-weight: 700;
        font-size: 1.6rem;
        color: $primary-color;
        text-transform: uppercase;
    }
    @include media-breakpoint-down(md) {
        display: block;
    }
}
.nav-mobile-open {
	.menu-bar-top {
		transform: rotate(45deg) translate(7px, 5px);
		-webkit-transform: rotate(45deg) translate(7px, 5px);
		transition: .5s;
		-webkit-transition: .5s;
	}
	.menu-bar-middle {
		opacity: 0;
	}
	.menu-bar-bottom {
		transform: rotate(-45deg) translate(10px, -8px);
		-webkit-transform: rotate(-45deg) translate(10px, -8px);
		transition: .5s;
		-webkit-transition: .5s;
        top: 22px;
	}
}






