// single
.single-post {
    margin-top: 40px;
    .row {
        margin: 15px -15px;
    }
    h1, h2, h3, h4, h5, h6, p {
        display: block;
        margin: 15px 0;
    }
    @include media-breakpoint-down(md) {
        margin-top: 15px;
    }
}

