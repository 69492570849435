// boxes
.news-full-image-box {
    height: 400px;
    display: block;
    position: relative;
    overflow: hidden;
    &:after {
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        content: "";
        z-index: 1;
        @extend .transition;
    }
    .desc {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 20px 15px;
        z-index: 2;
        color: $white;
    }
    .heading-large {
        margin-bottom: 20px;
    }
    .heading-medium {
        margin-bottom: 15px;
    }
    &:hover {
        &:after {
            background: rgba(0, 0, 0, 0.6);
        }
    }
    &.small-box {
        height: 185px;
    }
    @include media-breakpoint-down(md) {
        height: 230px;
        .heading-large {
            margin-bottom: 10px;
        }
        .heading-medium {
            margin-bottom: 10px;
        }
    }
}
.category { 
    position: relative;
    z-index: 2;
    display: inline-block;
    padding: 5px 10px;
    text-transform: uppercase;
    color: $white;
    margin: 15px 0 0 15px;
}
.date {
    display: inline-block;
    padding-left: 18px;
    position: relative;
    z-index: 2;
    &:before {
        background: url(../images/svg/clock.svg) center center no-repeat;
        background-size: cover;
        width: 12px;
        height: 12px;
        display: block;
        content: "";
        position: absolute;
        top: 3px;
        left: 0;
    }
    &.date-black {
        &:before {
            background-image: url(../images/svg/clock-2.svg);
            top: 3px;
        }
    }
}
.comments {
    display: inline-block;
    padding-left: 18px;
    position: relative;
    z-index: 2;
    float: right;
    &:before {
        background: url(../images/svg/comments.svg) center center no-repeat;
        background-size: cover;
        width: 12px;
        height: 12px;
        display: block;
        content: "";
        position: absolute;
        top: 3px;
        left: 0;
    }
}
.blue {
    background: $blue;
}
.green {
    background: $green;
}
.red {
    background: $primary-color;
}


.news-sep-image-box {
    display: block;
    .heading-large {
        line-height: 1.2;
    }
    .image {
        height: 400px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    p {
        text-align: justify;
    }
    .btn-border {
        margin-top: 30px;
    }
    &:hover {
        .btn-border {
            border-color: $primary-color;
        }
    }
    @include media-breakpoint-down(md) {
        .image {
            height: 230px;
        }
    }
}

.event-image-item {
    display: block;
    padding-bottom: 15px;
    margin-bottom: 12px;
    .image {
        height: 150px;
        margin-bottom: 10px;
    }
    .date {
        margin: 5px 0;
    }
    p {
        text-align: justify;
    }
}

.event-text-item {
    margin-bottom: 10px;
    padding-bottom: 15px;
    display: block;
    .heading-box-small {
        margin-bottom: 5px;
    }
}

.article-image-item {
    display: block;
    .image {
        height: 150px;
        margin-bottom: 10px;
    }
    .date {
        margin-top: 5px;
    }
}

.news-image-small-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    .image {
        width: 80px;
        height: 80px;
    }
    .category {
        font-size: 1rem;
        margin: 10px 0 0 -5px;
    }
    .desc {
        width: calc(100% - 80px);
        padding-left: 15px;
    }
    .heading-box-small {
        line-height: 1.2;
    }
    .date {
        font-size: 1.2rem;
        margin-top: 7px;
    }
}






























