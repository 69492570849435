// lists
.social-list {
    li {
        display: inline-block;
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
    svg {
        width: 20px;
        height: 20px;
        @extend .transition;
    }
    a {
        &:hover {
            svg {
                fill: $primary-color;
            }
        }
    }
}
