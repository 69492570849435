html { font-family: sans-serif; font-size: 62.5%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }
body {
    font: {
        family: 'Roboto', sans-serif;
        weight: 400;
		size: 1.3rem;
	}
    color: #000000;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    margin: 0; 
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary { display: block; }
audio, canvas, progress, video { display: inline-block; vertical-align: baseline; }
audio:not([controls]) { display: none; height: 0; }
[hidden], template { display: none; }
abbr[title] { border-bottom: 1px dotted; }
p, h1, h2, h3, h4, h5, h6 { margin: 0; }
b, strong { font-weight: bold; }
dfn { font-style: italic; }
mark { background: #ff0; color: #000; }
small { font-size: 80%; }
sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
sup { top: -0.5em; }
sub { bottom: -0.25em; }
img { max-width: 100%; border: 0; }
svg:not(:root) { overflow: hidden; }
figure { margin: 1em 40px; }
hr { -moz-box-sizing: content-box; box-sizing: content-box; height: 0; }
pre { overflow: auto; }
code, kbd, pre, samp { font-family: monospace, monospace; font-size: 1em; }
button, input, optgroup, select, textarea { color: inherit; font: inherit; margin: 0; }
button { overflow: visible; }
button, select { text-transform: none; }
button, html input[type="button"], input[type="reset"], input[type="submit"] { -webkit-appearance: button; cursor: pointer; }
button[disabled], html input[disabled] { cursor: default; }
button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }
input { line-height: normal; }
input:focus, select:focus, textarea:focus, button:focus { outline: none; }
input[type="checkbox"], input[type="radio"] { box-sizing: border-box; padding: 0; }
input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button { height: auto; }
input[type="search"] { -webkit-appearance: textfield; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; box-sizing: content-box; }
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }
::-webkit-input-placeholder { opacity: 1 !important; color: inherit !important; }
::-moz-placeholder { opacity: 1 !important; color: inherit !important; }
::-ms-input-placeholder { opacity: 1 !important; color: inherit !important; }
fieldset { border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }
legend { border: 0; padding: 0; }
textarea { overflow: auto; }
optgroup { font-weight: bold; }
table { border-collapse: collapse; border-spacing: 0; }
td, th { padding: 0; }
ul { margin: 0; padding: 0; }
li { list-style-type: none; }
a {
    background-color: transparent;
	text-decoration: none;
	color: inherit;
	transition: all 0.2s ease;
	-webkit-transition: all 0.2s ease;
    outline: 0;
    &:active { outline: 0; color: #7e0000; }
	&:hover { text-decoration: none; outline: 0; color: #7e0000; }
	&:focus { text-decoration: none; outline: 0; color: #7e0000; }
}
.outline {
	position: absolute;
	clip: rect(0px 0px 0px 0px);
	*clip: rect(0 0 0 0);
	font-size: 0;
	line-height: 0;
}
.container {
	max-width: 1200px;
	width: 100%;
}