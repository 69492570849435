// about us
.about-us-section {
    border-bottom: 1px $grey solid;
    margin-top: 30px;
    padding-bottom: 30px;
    .heading-large {
        margin-bottom: 20px;
    }
    p {
        display: block;
        margin: 15px 0;
    }
    @include media-breakpoint-down(md) {
        margin-top: 15px;
        padding-bottom: 15px;
        .heading-large {
            margin-bottom: 10px;
        }
    }
}
