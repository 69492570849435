// typography
h1, h2, h3, h4, h5, h6 {
    letter-spacing: 0.05em;
}

.heading-small {
    font-weight: 700;
    font-size: 1.8rem;
}
.heading-medium {
    font-size: 2.4rem;
    @include media-breakpoint-down(sm) {
        font-size: 2rem;
    }
    @include media-breakpoint-down(xs) {
        font-size: 1.6rem;
    }
}
.heading-large {
    font-size: 3.6rem;
    @include media-breakpoint-down(sm) {
        font-size: 3rem;
    }
    @include media-breakpoint-down(xs) {
        font-size: 2.6rem;
        line-height: 1.2;
    }
}

.heading-box-small {
    font-weight: 700;
    font-size: 1.4rem;
}
.heading-box-medium {
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
}
