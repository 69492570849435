// helpers
.transition {
    transition: all 0.2s ease;
}
.background-settings {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.space-primary {
    margin-top: 15px;
    margin-bottom: 15px;
}
.space-top {
    margin-top: 30px;
}
.text-center {
    text-align: center;
}

.text-right{
  text-align: right;
}

.comment-input{
    width: 100%;
    height: 80px;
    margin-top: 10px;
}

.btn-submit{
    padding: 10px;
    background: #000;
    color: #fff; 
    border: none;
}

.pagination {
    display: inline-block;
}

.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
}